module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#1d3461","theme_color":"#1d3461","display":"minimal-ui","icon":"src/images/trascendemos-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/render/project/src/src/intl","languages":["en","es"],"defaultLanguage":"en","redirect":true,"redirectComponent":"/opt/render/project/src/src/components/redirect.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
